"use client";

import { QueryClientProvider } from "@tanstack/react-query";
import PlausibleProvider from "next-plausible";
import type { FC } from "react";
import { publicConfig } from "@/config.public";
import { queryClient } from "@/utils/query.utils";
interface Props {
  children: React.ReactNode;
}
const RootTemplate: FC<Props> = ({
  children
}) => {
  return <PlausibleProvider trackLocalhost={false} domain={publicConfig.host} data-sentry-element="PlausibleProvider" data-sentry-component="RootTemplate" data-sentry-source-file="template.tsx">
      <QueryClientProvider client={queryClient} data-sentry-element="QueryClientProvider" data-sentry-source-file="template.tsx">{children}</QueryClientProvider>
    </PlausibleProvider>;
};
export default RootTemplate;